import React from 'react';

import * as oktaReact from '@okta/okta-react';

import './container.css';

function Container() {
  const {
    authState: {accessToken, idToken}
  } = oktaReact.useOktaAuth();

  document.cookie = `access-token=${accessToken};max-age=604800;path=/;domain=.armis.com`;
  document.cookie = `armis-id-token=${idToken};max-age=604800;path=/;domain=.armis.com`;
  window.location.href = window.sessionStorage.getItem('rd');

  return (
    <div>
      <div className="body">
        <span>
          <span />
          <span />
          <span />
          <span />
        </span>
        <div className="base">
          <span />
          <div className="face" />
        </div>
      </div>
      <div className="longfazers">
        <span />
        <span />
        <span />
        <span />
      </div>
      <h1>Loading ...</h1>
    </div>
  );
}

export default Container;
