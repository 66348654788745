import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import {Security, LoginCallback, SecureRoute} from '@okta/okta-react';

import Container from './Container';

const {NODE_ENV, REACT_APP_ARMIS_OPS_OKTA_ISSUER, REACT_APP_ARMIS_OPS_OKTA_CLIENT_ID} = process.env;

function App() {
  const disableHTTPSCheck = NODE_ENV === 'development';
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let rd_val = params.get('rd') || '/';
  window.sessionStorage.setItem('rd', rd_val);
  return (
    <BrowserRouter>
      <Security
        clientId={REACT_APP_ARMIS_OPS_OKTA_CLIENT_ID}
        issuer={REACT_APP_ARMIS_OPS_OKTA_ISSUER}
        pkce={true}
        redirectUri={`${window.location.origin}/auth/callback`}
        disableHttpsCheck={disableHTTPSCheck}
      >
        <Switch>
          <Route path="/auth/callback" component={LoginCallback} />
          <SecureRoute component={Container} />
        </Switch>
      </Security>
    </BrowserRouter>
  );
}

export default App;
